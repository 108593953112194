import React, { Component } from 'react'
import Battle from './Battle'
import ErrorPage from './ErrorPage'
// import grain from '../images/img-noise-carti.png'
import BarLoader from 'react-spinners/BarLoader'
import { colors } from '../styles/theme';
import WindowSizeListener from 'react-window-size-listener'

import { Query } from 'react-apollo'
import gql from 'graphql-tag'

class BattleList extends Component {

  constructor() {
    super();

    this.state = {
      filter: "",
      orderBy: "latest",
      smallScreen: window.innerWidth < 700
    }
  }


  handleSearch = (filter) => {
    this.setState({filter})
  }

  renderMainBattle = (battle) => {
   if(!this.props.filter) {
      return (
        <div className="main-battle">
          <div className="content-wrapper">
            <h4 className="battle-header">up next</h4>
            <Battle username={this.props.username && this.props.username} main={true} battle={battle} />
            <h4 className="battle-footer">past battles</h4>
          </div>
        </div>
      )
    }
  }

  onResize = (windowSize) => {
    if(windowSize.windowWidth < "700") {
      this.setState({smallScreen: true})
    } else if(windowSize.windowWidth > "700") {
      this.setState({smallScreen: false})
    }
  }

  render() {
    return (
      <Query variables={{filter: this.props.filter, orderBy: this.state.orderBy
        , skip: !this.state.smallScreen && this.props.filter.length < 1 ? 1 : null
      }}
        query={BATTLES_QUERY}>
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <div className="homepage">
                <BarLoader
                  css={{
                    margin: "0 auto",
                    position: "relative",
                    top: "30%"
                  }}
                   sizeUnit={"px"}
                   size={50}
                   color={colors.blackThought}
                   loading={loading}
                />
              </div>
            )
          }
          if (error) return <ErrorPage />

          const battleList = data.battles;

          if(battleList && battleList.length === 0) {
            return (
              <div className="homepage">
                <h4 className="homepage__no-results">[No Results]</h4>
              </div>
            )
          } else {
            return (
              <Query variables={{filter: this.props.filter, orderBy: this.state.orderBy,
                first: 1
              }}  query={BATTLES_QUERY}>
                {({ data: dataTwo }) => {
                  return (
                    <div className="homepage">
                      <WindowSizeListener onResize={(windowSize) => this.onResize(windowSize)}/>
                      <div className="battles-wrapper">
                        {dataTwo && this.props.filter.length < 1 && !this.state.smallScreen && this.renderMainBattle(dataTwo.battles[0])}
                        <div className="battles">
                        {battleList.map(battle => <Battle username={this.props.username && this.props.username} key={battle.id} battle={battle} />)}
                        </div>
                      </div>
                    </div>
                  )
                }}
              </Query>
            )
          }
        }}
      </Query>
    )
  }
}

export default BattleList


const BATTLES_QUERY = gql`
  query battles($filter: String, $orderBy: String, $first: Int, $skip: Int) {
    battles(filter: $filter, orderBy: $orderBy, first: $first, skip: $skip) {
      id
      artists {
        name
      }
      date
      title
      image
      userVoteCount
    }
  }
`
