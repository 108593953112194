import React, { Component } from 'react'

// <p className="footer__text left">[COPYRIGHT © 2020]</p>
// <a className="footer__contact" href="mailto:malcolm@rapchr.io">Contact Us</a>
// <a className="footer__link" target="_blank" href=" https://nomaddigital.co/service-home "><p className="footer__text right">[made x shade]</p></a>
// <a className="footer__contact footer__text left" href="mailto:malcolm.bouzi@gmail.com">Contact Us</a>


class Footer extends Component {
  render() {
    return (
      <div className="footer">
      </div>
    )
  }
}

export default Footer
