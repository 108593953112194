import React, { Component } from 'react'

class ErrorPage extends Component {
  render() {
    return (
      <div className="error-page">
        <div className="error-page__error-wrapper">
          <h4 className="error-page__error-title">[Error]</h4>
          <p className="error-page__error-msg">{this.props.noMatch ? "Page does not exist..." : "Something went wrong.."}</p>
        </div>
      </div>
    )
  }
}

export default ErrorPage
