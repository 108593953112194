import React, { Component } from 'react'
import logo from "../images/logo.svg"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import { colors } from '../styles/theme';


class SplashPage extends Component {

  constructor() {
    super();

    this.state = {
      about: false
    }
  }

  render() {
    const url = process.env.REACT_APP_MAILCHIMP_URL;

    const SimpleForm = () => <MailchimpSubscribe url={url}/>

    return (
      <div className="splash-page">
        <a href="/">
          <p className="splash-page__back">Back to Verzuz Vote</p>
        </a>
        <div className="splash-page__contents">
          <img className="splash-page__logo" src={logo} alt="Rapchr Logo" />
          <div className="splash-page__contents">
            <p className="splash-page__msg">Hip-hop tech platform focused on community, culture, and the art of rap. Sign up to find out more about what we're building, and follow us on twitter <a target="_blank" className="splash-page__msg__link" href="https://twitter.com/rapchr">@rapchr</a></p>
          </div>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <div>
                <SimpleForm url={url} onSubmitted={formData => subscribe(formData)} />
                {status === "sending" && <div style={{ color: colors.carti, marginBottom: "20px" }}>sending...</div>}
                {status === "error" && <div style={{ color: colors.$wineHouse + 10, marginBottom: "20px" }} dangerouslySetInnerHTML={{__html: message}}/>}
                {status === "success" && <div style={{ color: colors.carti, marginBottom: "20px" }}>Subscribed !</div>}
              </div>
            )}
          />
          <div className="splash-page__contents-bottom">
            <p className="splash-page__about-btn" onClick={() => this.setState({about: !this.state.about})}>{this.state.about ? "About" : "About"}<span>{this.state.about ? "◄ "  : "►"}</span></p>
            <p
              className="splash-page__msg"
              style={{
                display: this.state.about ? "block" : "none",
              }}
            >Verzuz has been an amazing way for the community to come together, during unprecedented times, as an educational and celebratory occurrance. This app was made by a black engineer and black woman product designer as a symbol of what's possible when we come together to create our own spaces through technology.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default SplashPage
