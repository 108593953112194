import React, { Component } from 'react'
import { colors } from '../styles/theme';
import shadeImage from '../images/shade.jpg'
import malcolmImage from '../images/malcolm.jpg'


class AboutPage extends Component {



  render() {

    return (
      <div className="about-page">
      <div className="about-page__text-wrapper">
        <p className="about-page__text">This app was made to bring another dimension to the competitive spirit of the Verzuz battles. Vote for your round winners, connect in the chat, see who voted for what, and download graphics of your results to share, all in one space. This has no official connections to the Verzuz brand.</p>
      </div>
      <div className="about-page__section left">
          <div
            className="about-page__section__image"
            style={{backgroundImage: `url(${shadeImage})`}}
          >
          </div>
          <div className="about-page__section__bio-wrapper">
            <p className="about-page__section__bio-title">Shade Bakare</p>
            <p className="about-page__section__bio">I'm an Atlanta-based Product Designer + digital nomad with a passion for curating relevant digital experiences at the intersection of simplicity and authenticity. Check out more of my work over at <a target="_blank" href="http://shadebakare.com/">Made x Shade</a> and enjoy the platform!</p>
          </div>
        </div>
        <div className="about-page__section left">
          <div
            className="about-page__section__image"
            style={{backgroundImage: `url(${malcolmImage})`}}
          >
          </div>
          <div className="about-page__section__bio-wrapper">
            <p className="about-page__section__bio-title">Malcolm Bouzi</p>
            <p className="about-page__section__bio">I'm a software engineer and New York native who's passion for music led me to production, engineering and writing before I learned how to code. This is a taste of the kind of world I'm building with <a target="_blank" href="https://www.joinrapchr.com/">Rapchr</a>, a new social app for hip-hop fans to discuss and explore their taste. <a href="mailto:malcolm@joinrapchr.com">Get in touch</a></p>
          </div>
        </div>

        <div className="bump"></div>
      </div>
    )
  }
}

export default AboutPage
