import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import App from './components/App'
import * as serviceWorker from './serviceWorker';
import { AUTH_TOKEN } from './constants'
import { Provider as AlertProvider } from 'react-alert'
import { colors } from './styles/theme';

import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ReactApolloHooksProvider  } from 'react-apollo-hooks';
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { BrowserRouter } from 'react-router-dom'
import { setContext } from 'apollo-link-context'
import { split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import HttpsRedirect from 'react-https-redirect';

const uri = process.env.REACT_APP_API_URL || "http://localhost:4000";
// const websocketURI = process.env.REACT_APP_WEBSOCKET_URI || "ws://localhost:4000";

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN)
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const httpLink = createHttpLink({
  uri: uri
})

// const wsLink = new WebSocketLink({
//   uri: websocketURI,
//   options: {
//     reconnect: true,
//     lazy: true,
//     timeout: 3000,
//     connectionParams: {
//       authToken: localStorage.getItem(AUTH_TOKEN),
//     }
//   }
// })
//
// wsLink.subscriptionClient.on("connecting", () => {
//   console.log("connecting");
// });
//
// wsLink.subscriptionClient.on("connected", () => {
//   console.log("connected");
// });
//
// wsLink.subscriptionClient.on("reconnecting", () => {
//   console.log("reconnecting");
// });
//
// wsLink.subscriptionClient.on("reconnected", () => {
//   console.log("reconnected");
//   wsLink.subscriptionClient.close(false, false)
// });
//
// wsLink.subscriptionClient.on("disconnected", () => {
//   console.log("disconnected");
// });
//
// wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () =>
//   wsLink.subscriptionClient.maxConnectTimeGenerator.max;

// const link = split(
//   ({ query }) => {
//     const { kind, operation } = getMainDefinition(query)
//     return kind === 'OperationDefinition' && operation === 'subscription'
//   },
//   wsLink,
// )

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  fetchOptions: {
    mode: 'no-cors'
  }
})


const options = {
  position: "top center",
  timeout: 2500,
  offset: '11px',
  transition: "fade", // or scale
  containerStyle: {
    background: colors.carti,
    width: "35%",
    borderRadius: "36.6px",
    boxShadow: "0 4px 7px 0 rgba(0, 0, 0, 0.5)",
    color: colors.beachHouse,
    height: "40px",
    marginTop: "20px",
    fontWeight: "500",
    zIndex: "100"
  }
}

const AlertTemplate = ({ message, style, options, close }) => (
  <div style={style}>
    <p onClick={close}>x</p>
    <p style={{marginLeft: "10px"}}>{message ? message : "Error! Please try again."}</p>
  </div>
)



ReactDOM.render(
  <BrowserRouter>
    <ReactApolloHooksProvider client={client}>
      <ApolloProvider client={client}>
        <AlertProvider template={AlertTemplate} {...options}>
          <HttpsRedirect>
            <App client={client} />
          </HttpsRedirect>
        </AlertProvider>
      </ApolloProvider>
    </ReactApolloHooksProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
serviceWorker.unregister();
