import React, { Component } from 'react'
import check from '../images/check.svg'

class HiddenComment extends Component {

  constructor() {
    super();

    this.state = {
      hidden: true
    }
  }

  renderCheck = (user) => {
    return user.verified &&
      <img
        className="chat-list__scroll__comment-check"
        alt="check icon"
        src={check} 
      />
  }

  renderComment = (comment) => {
    const span = comment.hidden && <span onClick={() => this.setState({hidden: !this.state.hidden})} className="chat-list__scroll__comment-text__hide" >{comment.hidden && this.state.hidden ? "View" : "Hide"}</span>
    const user = comment.user;
    if(comment.hidden && this.state.hidden) {
      return <p className={"chat-list__scroll__comment-text hidden"}>{this.renderCheck(user)}This comment may contain sensitive vocabulary {span}</p>
    } else {
      return <p className="chat-list__scroll__comment-text">{this.renderCheck(user)}{comment.message} {span}</p>
    }
  }
  render() {
    const comment = this.props.comment;
    // const hideComment = this.props.hideComment;

    return this.renderComment(comment)
  }
}

export default HiddenComment
