import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import { colors } from '../styles/theme';


class Matchup extends Component {

  constructor() {
    super();

    this.state = {
      songChoice: "",
      optionOneCheck: false,
      optionTwoCheck: false,
      userList: false,
    }
  }

  handleSongChoice = (index, song) => {
    if(index === 0 && this.state.optionOneCheck) {
      return null
    } else if(index === 1 && this.state.optionTwoCheck) {
      return null
    } else {
      return song.id
    }
  }

  onChangeSong = (song, pullChoice, index) => {
    const songChoice = this.handleSongChoice(index, song);

    this.setState({
      songChoice: songChoice,
      optionOneCheck: index === 0 && !this.state.optionOneCheck,
      optionTwoCheck: index === 1 && !this.state.optionTwoCheck
    })
    pullChoice(songChoice)
  };

  handleVoteClick = (songId) => {
    this.props.renderUserList(songId)
  }

  renderCheckBox = (song, indexCheck, index, currentUserVoted, pullChoice, value, userId) => {
    if(indexCheck && userId && !currentUserVoted) {
      return (
        <div
          onClick={() => this.onChangeSong(song, pullChoice, index)}
          className={"container song" + (index + 1)}
        >
          <input
            onChange={() => {}}
            checked={index === 0 ? this.state.optionOneCheck : this.state.optionTwoCheck}
            className={"battle-page__matchups__song__check__" + (index + 1)}
            type="checkbox"
            name={song.id}
          />
         <span className="checkmark"></span>
       </div>
      )
    }
  }

  renderVoteCount = (song, indexCheck, index, currentUserVoted, songVotes, userId) => {
    if(indexCheck && songVotes && ((userId && currentUserVoted) || (!userId))) {
      return (
        <p
          onClick={() => this.handleVoteClick(song.id)}
          className={"battle-page__matchups__song__vote-count__" + (index + 1)}
          key={song.id}
          style={{
            borderColor: song.currentUserVoted ? colors.beachHouse : colors.blackThought,
            color: song.currentUserVoted ? colors.beachHouse : colors.blackThought
          }}
        >
          {songVotes}
        </p>
      )
    }

  }

  renderSongTitle = (song, index, userId) => {

    if(song.playlistLink) {
      return(
        <a
          key={index}
          className="battle-page__matchups__song__link"
          target="_blank"
          href={song.playlistLink}
          rel="noopener noreferrer"
        >
          <p
            style={{
              color: song.currentUserVoted ? colors.beachHouse : colors.blackThought,
            }}
            className={(this.props.graphic ? "graphic " : "") + "battle-page__matchups__song__song-title__" + (index + 1)}
          >
            {song.name}
          </p>
        </a>
      )
    } else {
      return(
        <p
          key={index}
          style={{
            color: song.currentUserVoted ? colors.beachHouse : colors.blackThought,
          }}
          className={(this.props.graphic ? "graphic " : "") + "battle-page__matchups__song__song-title__" + (index + 1)}>
          {song.name}
        </p>
      )
    }
  }

  renderSong = (song, pullChoice, index, currentUserVoted, userId) => {
    const songVotes = song.percentage && song.percentage + "%"

    return (
      <div key={song.id} className={"battle-page__matchups__song" + " song" + (index + 1)}>
        {!this.props.graphic && this.renderVoteCount(song, index === 0, index, currentUserVoted, songVotes, userId)}
        {this.renderCheckBox(song, index === 0, index, currentUserVoted, pullChoice, "1", userId)}
        {this.renderSongTitle(song, index, userId)}
        {this.renderCheckBox(song, index === 1, index, currentUserVoted, pullChoice, "2", userId)}
        {!this.props.graphic && this.renderVoteCount(song, index === 1, index, currentUserVoted, songVotes, userId)}
      </div>
    )

  }

  render() {
    const matchup = this.props.matchup
    const pullChoice = this.props.pullChoice
    const userId = this.props.userId
    const firstColor = this.props.matchup.songs[0] && this.props.matchup.songs[0].currentUserVoted ? colors.blackThought : colors.beachHouse;
    const secondColor = this.props.matchup.songs[1] && this.props.matchup.songs[1].currentUserVoted ? colors.blackThought : colors.beachHouse;

    return (
      <div
        style={{
          background: `linear-gradient(to right, ${firstColor} 50%, ${secondColor} 50%)`
        }}
        className={(this.props.graphic ? "graphic " : "") + "battle-page__matchups__matchup"}
      >
        {matchup.songs.map((song, index) => this.renderSong(song, pullChoice, index, matchup.currentUserVoted, userId))}
      </div>
    )
  }
}

export default Matchup
