import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { AUTH_TOKEN } from '../constants'
import SpotifyLogin from 'react-spotify-login';
import rapchrLogo from "../images/logo.svg"
import TwitterLogin from "react-twitter-login";

import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'

class Login extends Component {
  state = {
    login: this.props.login, // switch between Login and SignUp
    email: '',
    username: '',
    password: '',
    verified: false,
    click: false,
    error: false,
    errorMsg: "",
    loading: false
  }

  componentDidMount() {
   this._isMounted = true;
 }

 componentWillUnmount() {
   this._isMounted = false;
 }

  renderErrorMessage = () => {
    if(this.state.error) {
      return (
        <p className="error-msg">{this.state.errorMsg}</p>
      )
    }
  }

  onSuccess = (response, mutation) => {
   this.setState({error: false, loading: true})
   mutation({
     variables: { accessToken: response.access_token}
   })
   .then((res) => {
     if(this.mounted){
      this.setState({loading: false})
    }
     this.onRedirect()
     window.location.reload();
   })
   .catch((error) => {
     this.setState({loading: false})
     this.setState({error: true, errorMsg: "Make sure your account matches your Spotify email"})
   })
 }

 onRedirect = () => {
   if((this.props.location.state && this.props.location.state.prevPath !== "/signup") || (this.props.location.state && this.props.location.state.prevPath !== "/login")) {
     this.props.history.push(this.props.location.state.prevPath)
   } else if(this.state.prevPath) {
     this.props.history.push(this.state.prevPath)
   } else {
     this.props.history.push(`/`)
   }
 }

  onFailure = (response) => {
    this.setState({click: false, error: true, errorMsg: "Make sure your account email matches your Spotify email"})
  }


  onSubmit = (e, mutation, state) => {
    e.preventDefault()
    this.setState({loading: true, error: false})

    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (reg.test(state.email) === false) {
      this.setState({loading: false, error: true, errorMsg: "Invalid email address"})
    } else {
      mutation({
        variables: { ...state }
      })
      .then((res) => {
        if(this.mounted){
          this.setState({loading: false})
        }
        this.onRedirect()
        window.location.reload();
      })
      .catch((error) => {
        this.setState({loading: false, error: true, errorMsg: "This input combination does not match our records. Please double-check and try again."})
      })
    }
  }

  authHandler = (err, data, mutation) => {
    if(err) {
      this.setState({loading: false})
      this.setState({error: true, errorMsg: "Something went wrong, please reload the page and try again."})
    } else if(data) {
      mutation({
        variables: { screenName: data.screen_name}
      })
      .then((res) => {
        if(this.mounted){
         this.setState({loading: false})
       }
        this.onRedirect()
        window.location.reload();
      })
      .catch((error) => {
        this.setState({loading: false})
        this.setState({click: false, error: true, errorMsg: "Make sure your account matches your Twitter username."})
      })
    }

   };

  render() {
    const { login, username, email, password, verified } = this.state

    return (
      <div className="onboarding">
        <a className="onboarding__title" href="/about">
          <p>[about]</p>
        </a>
        {this.renderErrorMessage(login)}
        <div className="onboarding__cta-wrapper">
            <Mutation
              mutation={login ? LOGIN_MUTATION : SIGNUP_MUTATION}
              variables={{ email, password, username, verified }}
              onCompleted={data => this._confirm(data)}
            >
              {mutation => (
                <form onSubmit={(e) => this.onSubmit(e, mutation, this.state)}>
                  <div>
                    {!login && (
                      <div className="onboarding__input-wrapper">
                          <input
                            value={username}
                            onChange={e => this.setState({ username: e.target.value })}
                            type="text"
                            name="username"
                            className="onboarding__input"
                            placeholder="username"
                          />
                          <label className="onboarding__label" htmlFor="username">
                            username
                          </label>
                      </div>
                    )}
                    <div className="onboarding__input-wrapper">
                      <input
                        value={email}
                        onChange={e => this.setState({ email: e.target.value })}
                        type="text"
                        name="email"
                        className="onboarding__input"
                        placeholder="email"
                      />
                      <label className="onboarding__label" htmlFor="email">
                         email
                      </label>
                    </div>
                    <div className="onboarding__input-wrapper">
                      <input
                        value={password}
                        onChange={e => this.setState({ password: e.target.value })}
                        type="password"
                        name="password"
                        className="onboarding__input"
                        placeholder="password"
                      />
                      <label className="onboarding__label" htmlFor="password">
                        password
                      </label>
                    </div>
                  </div>
                  <button
                    type="submit"
                    style={{
                      cursor: this.state.loading ? "not-allowed" : "pointer",
                      color: !this.state.loading ? "#fff" : "#f1e3ce54",
                      background: !this.state.loading ? "rgb(63, 52, 47)" : "rgba(63, 52, 47, 0.75)"
                    }}
                    className="onboarding__cta"
                    onClick={this.state.loading ? () => {} : (e) => this.onSubmit(e, mutation, this.state)}
                  >
                    {login ? 'sign in' : 'create account'}
                  </button>
                </form>
              )}
            </Mutation>
          <Link
            to={this.state.login ? "/signup" : "/login"}
            className="onboarding__text"
            onClick={() => this.setState({login: !this.state.login, prevPath: this.props.location.state && this.props.location.state.prevPath})}
          >
            {login ? 'need to create an account?' : 'already have an account?'}
          </Link>
        </div>
      </div>
    )
  }

  _confirm = async data => {
    const { token } = this.state.login ? data.login : data.signup
    this._saveUserData(token)
    this.onRedirect()
  }

  _twitterConfirm = async data => {
    const { token } = data.twitterLogin
    this._saveUserData(token)
    this.onRedirect()
  }

  _spotifyConfirm = async data => {
    const { token } = data.spotifyLogin
    this._saveUserData(token)
    this.onRedirect()
  }

  _saveUserData = token => {
    localStorage.setItem(AUTH_TOKEN, token)
  }
}


const SIGNUP_MUTATION = gql`
  mutation SignupMutation($verified: Boolean!, $username: String!, $email: String!, $password: String!) {
    signup(verified: $verified, username: $username, email: $email, password: $password) {
      token
    }
  }
`

const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`

const SPOTIFY_LOGIN_MUTATION = gql`
  mutation SpotifyLoginMutation($accessToken: String) {
    spotifyLogin(accessToken: $accessToken) {
      token
    }
  }
`

const TWITTER_LOGIN_MUTATION = gql`
  mutation TwitterLoginMutation($screenName: String) {
    twitterLogin(screenName: $screenName) {
      token
    }
  }
`

export default withRouter(Login)
