const colors = {
  beachHouse: '#f1e3ce',
  beachHouseLight: '#fff1dc',
  blackThought: '#2c201e',
  carti: '#c77d4a',
  cartiLight: '#ea9458',
  frankOcean: '#486279',
  frankOceanLight: '#5c7d9a',
  wineHouse: '#722a1f',
  bluePrint: '#181c2f'
};

export {
  colors
}
