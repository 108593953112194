import React, {useState, useRef} from 'react'
import { useQuery } from 'react-apollo-hooks';
import Matchup from '../components/Matchup'
import UserList from '../components/UserList'
import exit from '../images/exit.svg'
import gql from 'graphql-tag'

import { colors } from '../styles/theme';
import { isMobile } from "react-device-detect";
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import BarLoader from 'react-spinners/BarLoader'
import ErrorPage from './ErrorPage'



const renderMatchup = (matchup, index) => {
  return (
    <Matchup
      key={matchup.id}
      matchup={matchup}
      graphic={true}
    />
  )
}


const renderArtist = (artist, index, userWinner) => {
  if(artist.index === 1) {
    return (
      <div key={artist.index} className={"graphic battle-page__artist__header__" + artist.index}>
        <div className={"graphic battle-page__artist__header__wrapper__" + artist.index}>
          <h2
            className={"graphic battle-page__artist__header__name__" + artist.index}
          >
            {artist.name}
          </h2>
          <div
            className={"graphic battle-page__artist__header__image__" + artist.index}
            style={{
              backgroundImage: `url(${artist.image})`}}>
          </div>
        </div>
        <p className="battle-page__artist__user-count__1">{artist.userVoteCount} {artist.userVoteCount === 1 ? "vote" : "votes"}</p>
      </div>
    )
  } else {
    return (
      <div key={artist.index} className={"graphic battle-page__artist__header__" + artist.index}>
        <div className={"graphic battle-page__artist__header__wrapper__" + artist.index}>
          <div className={"graphic battle-page__artist__header__image__" + artist.index}
            style={{backgroundImage: `url(${artist.image})`}}></div>
          <h2
            className={"graphic battle-page__artist__header__name__" + artist.index}
          >
            {artist.name}
          </h2>
        </div>
        <p className="battle-page__artist__user-count__2">{artist.userVoteCount} {artist.userVoteCount === 1 ? "vote" : "votes"}</p>
      </div>
    )
  }
}


const renderUserChoices = (battle, setSongId, userId, votes, setVotes, setUserList) => {
  if(battle.matchups && battle.matchups.length > 0) {
    return battle.matchups.map((matchup, index) => {
      if(index < 20) {
        return renderMatchup(matchup, index, votes, setVotes, userId, setUserList, setSongId);
      }
    })
  }
}

const renderResults = (userWinner) => {
  const {winner, winnerPercentage, loserPercentage} = userWinner

  const firstArtistNumber = winner && winner.index === 1 ? winnerPercentage : loserPercentage
  const secondArtistNumber = winner && winner.index === 2 ? winnerPercentage : loserPercentage

  return (
    <div className="battle-page__results">
      <p className="battle-page__results__1">{firstArtistNumber}%</p>
      <p className="battle-page__results__2">{secondArtistNumber}%</p>
    </div>
  )

}

const renderBonus = (battle) => {
  const bonusCount = battle.bonusCount

  if(bonusCount === 1) {
    return <p className="battle-page__graphic__bonus">*1 bonus round</p>
  } else if(bonusCount > 1) {
    return <p className="battle-page__graphic__bonus">*{bonusCount} bonus rounds</p>
  }
}

const renderDownloadButton = (username, battle, printDocument) => {
  return (
    <div className="battle-page__graphic__button-wrapper">
      <button
        onClick={printDocument}
        className="battle-page__graphic__button"
      >
        Download
      </button>
    </div>
  )
}

const ResultsGraphic = ({battleId, username, closeGraphic, shareGraphic, printDocument}) => {

  const { data, loading, error } = useQuery(BATTLE_QUERY, {
   variables: {
     battleId: battleId
    }
  });

  if (loading) {
    return (
      <div className="battle-page__graphic">
        <BarLoader
          css={{
            margin: "0 auto",
            position: "relative",
            top: "40%"
          }}
           sizeUnit={"px"}
           size={50}
           color={colors.beachHouse}
           loading={loading}
        />
      </div>
    )
  }

  if (error) {
    if (error) return <ErrorPage />
   }

   if(data && data.battle) {
     const battle = data.battle
     const {userWinner} = battle
    return (
      <div className="battle-page__graphic">
        <div className="battle-page__graphic__wrapper">
        <div id="divToPrint">
          <div className="battle-page__graphic__msg-wrapper">
            <p className="battle-page__graphic__msg-txt">On your laptop/desktop? Download straight to your device!</p>
            <p className="battle-page__graphic__msg-txt">On your phone? Take a screenshot!</p>
          </div>
          <img
            onClick={closeGraphic}
            className="battle-page__graphic__exit"
            src={exit}
            alt="close icon"
          />
          {username && <p className="battle-page__graphic__username">@{username}</p>}
          <div className="graphic battle-page__artist__header">
            {battle.artists.map((artist, index) => renderArtist(artist, index, userWinner))}
            {renderBonus(battle)}
            {renderResults(userWinner)}
          </div>
          <div className="graphic battle-page__matchups">
            <div className="divider"></div>
            {renderUserChoices(battle)}
          </div>
          <p className="battle-page__graphic__site-name">verzuzvote.com</p>
          </div>
          </div>
        {shareGraphic && !isMobile && renderDownloadButton(username, battle, printDocument)}
      </div>
    )
  }
}


export default ResultsGraphic

const BATTLE_QUERY = gql`
  query battle($battleId: ID!, $userId: ID) {
    battle(battleId: $battleId, userId: $userId) {
      id
      image
      artists {
        name
        image
        index
        userVoteCount
      }
      userWinner {
        winner {
          id
          index
        }
        winnerPercentage
        loserPercentage
      }
      bonusCount
      title
      matchups {
       id
       currentUserVoted
       songs {
         id
         name
         percentage
         currentUserVoted
         index
       }
     }
    }
  }
`
