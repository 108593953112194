import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { AUTH_TOKEN } from '../constants'
import searchIcon from "../images/search.png"
import clearIcon from "../images/cancel.svg"
import closeIcon from "../images/exit.svg"



class Header extends Component {

  constructor() {
    super();

    this.state = {
      modal: false,
      mainPath: ""
    }
  }

  handleSearchFilter = e => {
    e.preventDefault()
    if(this.props.location.pathname !== "/") {
      this.props.history.push('/')
      this.setState({mainPath: this.props.location.pathname})
    }
    this.props.handleSearch(e.target.value)
    if(e.target.value.length === 1 && !this.state.mainPath) {
      this.setState({mainPath: this.props.location.pathname})
    }
    if(e.target.value === "" && this.state.mainPath.includes("battle")) {
      this.props.history.push(this.state.mainPath)
      this.setState({mainPath: ""})
    }
  }

  onKeyDownHandler = e => {
    if (e.keyCode === 13) {
      this.setState({modal: false});
    }
  };

  clearFilter = () => {
    this.props.handleSearch("")
  }

  renderModal = () => {
    if(this.state.modal) {
      return (
        <div className="search-modal">
          <div className="search-modal__contents">
            <h4 className="search-modal__header">[search]</h4>
            <img
              onClick={() => this.setState({modal: false})}
              className="search-modal__close"
              alt="close icon"
              src={closeIcon}
            />
            <input
              value={this.props.filter}
              className="search-modal__input"
              placeholder="Type something"
              onChange={(e) => this.handleSearchFilter(e)}
              onSubmit={() => this.setState({modal: false})}
              onKeyDown={this.onKeyDownHandler}
            />
            <img
              onClick={() => this.clearFilter()}
              className="search-modal__clear"
              alt="clear icon"
              src={clearIcon}
              style={{display: this.props.filter.length > 0 ? "block" : "none"}}
            />
            <p className="search-modal__msg">press ENTER to submit</p>
          </div>
        </div>
      )
    }
  }

  handleClick = () => {
    this.setState({modal: !this.state.modal})
  }

  render() {
    const authToken = localStorage.getItem(AUTH_TOKEN)

    return (
      <div
        className="header"
        style={{

        }}
      >
        {this.renderModal()}
        <div className="header__logos">
          <a href="/" className="header__logos__text">[home]</a>
          <a href="/about" className="header__logos__text about">[about]</a>
        </div>
        <div className="header__cta">
          {this.props.search && <div className="header__cta__search">
            <img
              onClick={() => this.setState({modal: !this.state.modal})}
              alt="Search Icon" className="header__cta__search"
              src={searchIcon}
            />
          </div>}
          {authToken ? (
            <div
              className="header__cta__text"
              onClick={() => {
                localStorage.removeItem(AUTH_TOKEN)
                window.location.reload();
              }}
            >
              [logout]
            </div>
          ) : (
            <Link
              to="/signup"
              to={{pathname: '/signup', state: { prevPath: this.props.location.pathname }}}
              className="header__cta__text"
            >
              [sign up]
            </Link>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(Header)
