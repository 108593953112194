import React, { Component } from 'react'
import { timeDifferenceForDate } from '../utils'
import grain from '../images/img-noise-beach.png'
import insta from '../images/instagram.svg'
import HiddenComment from './HiddenComment'
import { Query, Mutation } from 'react-apollo'
import gql from 'graphql-tag'

import BarLoader from 'react-spinners/BarLoader'
import { colors } from '../styles/theme';
import LoadingBar from 'react-top-loading-bar';


class ChatList extends Component {

  constructor() {
    super();

    this.state = {
      filter: "",
      orderBy: "latest",
      message: "",
      scrollHeight: 240,
      mutation: false
    }
  }


  scrollToBottomOnLoad = () => {
    var objDiv = document.getElementById("thang");
    if(objDiv && !this.state.message && !this.state.mutation) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  }

  scrollToBottom = (mutation) => {
    var objDiv = document.getElementById("thang");
    if (objDiv) {
      if(objDiv.scrollTop !== this.state.scrollHeight) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }
    }
    this.setState({scrollHeight: this.state.scrollHeight + 44, mutation: true})
  }

  onKeyDownHandler = (e, postMutation, battleId, setError, comments, setLoadingBarProgress) => {
    if (e.keyCode === 13) {
      this.handlePostSubmit(postMutation, battleId, setError, comments, setLoadingBarProgress)
    }
  };


  renderMessage = (comment) => {
    const user = comment.user;

    return (
      <div key={comment.id} className="chat-list__scroll__comment-wrapper">
        <p className="chat-list__scroll__comment-user">{comment.user.username}</p>
        <HiddenComment comment={comment} />
        <p className="chat-list__scroll__date">{timeDifferenceForDate(comment.createdAt)}</p>
      </div>
    )
  }

   // _subscribeToNewComments = (subscribeToMore) => {
   //
   //   subscribeToMore({
   //     document: NEW_COMMENTS_SUBSCRIPTION,
   //     updateQuery: (prev, { subscriptionData }) => {
   //       if (!subscriptionData.data) return prev
   //       const newComment = subscriptionData.data.newComment
   //       const exists = prev.chat.find(({ id }) => id === newComment.id);
   //
   //       if (exists) return prev;
   //       setTimeout(this.scrollToBottom, 1);
   //       return {
   //         chat: [
   //            ...prev.chat,newComment],
   //         count: prev.chat.length + 1,
   //         __typename: newComment.__typename
   //       }
   //     }
   //   })
   // }


   handlePostSubmit = (postMutation, battleId, setError, comments, setLoadingBarProgress) => {
     if(this.state.message.length === 0) {
       setError(true)
       setTimeout(() => setError(false), 4000);
     } else {
       setLoadingBarProgress(50)
       postMutation({
        variables: {
          message: this.state.message,
          battleId: battleId,
        },
        update: (store, data) => this.updateCacheAfterSubmit(store, data, comments, battleId)
      })
      .then((res) => {
        setLoadingBarProgress(100)
        this.setState({
          message: ""
        })
      })
      .catch((error) => {
        setLoadingBarProgress(0)
        setError(true)
        setTimeout(() => setError(false), 4000);
      })
     }
   }

  updateCacheAfterSubmit = (store, data, comments, battleId) => {
     const queryData = store.readQuery({ query: COMMENTS_QUERY, variables: {battleId: battleId} })
     const newData = data.data.post;
     const oldData = queryData.chat;

     oldData.push(newData)
     store.writeQuery({ query: COMMENTS_QUERY, variables: {battleId: battleId}, data: queryData})
     setTimeout(this.scrollToBottom, 1);
   }

   handleChange = (e) => {
     e.preventDefault()
     this.setState({
       message: e.target.value
     })
   }

   // {this.props.userId &&
   //   <div className="chat-list__scroll__input-wrapper">
   //     <input
   //       onChange={e => this.handleChange(e)}
   //       value={this.state.message}
   //       className="chat-list__scroll__input"
   //       placeholder="add a comment..."
   //       onKeyDown={(e) => this.onKeyDownHandler(e, postMutation, this.props.battle.id, setError)}
   //     />
   //     <button
   //       className="chat-list__scroll__button"
   //       onClick={() => this.handlePostSubmit(postMutation, this.props.battle.id, setError)}
   //     >
   //       Post
   //     </button>
   //   </div>
   // }

  render() {

    const {setError, playlist, setLoadingBarProgress } = this.props;

    return (
      <div style={{backgroundImage: `url(${this.props.battle.blurredImage ? this.props.battle.blurredImage : this.props.battle.image})`}} className="chat-list">
        <a
          rel="noopener noreferrer"
          className="chat-list__insta-cta__link"
          target="_blank"
          href="https://www.instagram.com/verzuztv/?hl=en"
        >
          <div style={{top: playlist ? "30px" : "80px"}}  className="chat-list__insta-cta">
              <h2 className="chat-list__insta-cta__text">watch on</h2>
            <img alt="Instagram Logo" className="chat-list__insta-cta__logo" src={insta} />
          </div>
        </a>
        <Query variables={{battleId: this.props.battle.id}} query={COMMENTS_QUERY}>
          {({ loading, error, data, subscribeToMore }) => {
            if (loading) {
              return (
                <BarLoader
                  css={{
                    margin: "0 auto",
                    position: "relative",
                    top: "200px",
                    zIndex: 3
                  }}
                   sizeUnit={"px"}
                   size={50}
                   color={colors.blackThought}
                   loading={loading}
                />
              )
            }
            if (error) {
              return (
                <div className="list-error-msg">Something went wrong, reload the page to see the comments.</div>
              )
            }

            // this._subscribeToNewComments(subscribeToMore)
            const comments = data.chat;

            return (
              <Mutation mutation={POST_COMMENT}>
                {(postMutation, { loading: loadingTwo, error: errorTwo, data: dataTwo }) => {
                  setTimeout(this.scrollToBottomOnLoad, 1);
                  return (
                    <div style={{top: playlist ? "50px" : "110px"}} className="chat-list__wrapper">
                      <div
                        style={{
                          borderBottomLeftRadius: this.props.userId ? "0" : "4px",
                          borderBottomRightRadius: this.props.userId ? "0" : "4px",
                          border: comments.length === 0 && "1px solid"
                        }}
                        className="chat-list__scroll"
                        id="thang"
                      >
                        <div style={{height: this.props.userId ? "40px" : "30px"}} className="chat-list__gradient">
                        </div>
                        {comments.map(comment => this.renderMessage(comment))}
                      </div>
                      {this.props.userId &&
                        <div className="chat-list__scroll__input-wrapper">
                          <input
                            onChange={e => this.handleChange(e)}
                            value={this.state.message}
                            className="chat-list__scroll__input"
                            placeholder="add a comment..."
                            onKeyDown={(e) => this.onKeyDownHandler(e, postMutation, this.props.battle.id, setError, comments, setLoadingBarProgress)}
                          />
                          <button
                            className="chat-list__scroll__button"
                            onClick={() => this.handlePostSubmit(postMutation, this.props.battle.id, setError, comments, setLoadingBarProgress)}
                          >
                            Post
                          </button>
                        </div>
                      }
                      <div className="chat-list__blur">
                      </div>
                    </div>
                  )
                }}
              </Mutation>
            )
          }}
        </Query>
        <div style={{backgroundImage: `url(${grain})`}} className="chat-list__grain">
        </div>
      </div>
    )
  }
}

export default ChatList

const COMMENTS_QUERY = gql`
  query chat($battleId: ID!) {
    chat(battleId: $battleId) {
      id
      message
      battle {
        title
      }
      user {
        username
        verified
      }
      createdAt
      hidden
    }
  }
`
const POST_COMMENT = gql`
  mutation post($battleId: ID!, $message: String!) {
    post(battleId: $battleId, message: $message) {
      id
      message
      battle {
        title
      }
      user {
        username
        verified
      }
      createdAt
      hidden
    }
  }
`

// const NEW_COMMENTS_SUBSCRIPTION = gql`
//   subscription {
//     newComment {
//       id
//        message
//        battle {
//         title
//       }
//       user {
//         username
//         verified
//       }
//       createdAt
//       hidden
//     }
//   }
// `
