import React, {useState, useRef} from 'react'
import { Link } from 'react-router-dom'
import Matchup from '../components/Matchup'
import ChatList from '../components/ChatList'
import UserList from '../components/UserList'
import ErrorPage from '../components/ErrorPage'
import ResultsGraphic from '../components/ResultsGraphic'
import exit from '../images/exit.svg'
import shareIcon from '../images/share-icon.svg'
import gql from 'graphql-tag'
import { useQuery, useMutation, useSubscription } from 'react-apollo-hooks';
import SpotifyPlayer from 'react-spotify-player';

import BarLoader from 'react-spinners/BarLoader'
import { colors } from '../styles/theme';
import LoadingBar from 'react-top-loading-bar';
import { isMobile } from "react-device-detect";
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';


const pullChoice = (choice, index, votes, setVotes, matchupId) => {
  if(votes[index] && !choice) {
    votes[index] = {}
  } else {
    votes[index] = {songId: choice, matchupId: matchupId}
  }
  setVotes(votes)
}

const renderMatchup = (matchup, index, votes, setVotes, userId, setUserList, setSongId) => {
  return (
    <Matchup
      renderUserList={(songId) => renderUserList(songId, setSongId, setUserList)}
      key={matchup.id}
      userId={userId}
      matchup={matchup}
      pullChoice={(choice) => pullChoice(choice, index, votes, setVotes, matchup.id)}
    />
  )
}


const renderArtist = (artist, index) => {
  if(artist.index === 1) {
    return (
      <div key={artist.index} className={"battle-page__artist__header__" + artist.index}>
        <div className={"battle-page__artist__header__wrapper__" + artist.index}>
          <h2 style={{direction: artist.name === "T.I." && "ltr"}} className={"battle-page__artist__header__name__" + artist.index}>{artist.name}</h2>
          <div className={"battle-page__artist__header__image__" + artist.index} style={{backgroundImage: `url(${artist.image})`}}></div>
        </div>
      </div>
    )
  } else {
    return (
      <div key={artist.index} className={"battle-page__artist__header__" + artist.index}>
        <div className={"battle-page__artist__header__wrapper__" + artist.index}>
          <div className={"battle-page__artist__header__image__" + artist.index} style={{backgroundImage: `url(${artist.image})`}}></div>
          <h2 className={"battle-page__artist__header__name__" + artist.index}>{artist.name}</h2>
        </div>
      </div>
    )
  }
}

const renderUserList = (songId, setSongId, setUserList) => {
  setSongId(songId)
  setUserList(true)
}

const setBoth = (newMatchup, setSuccess, setNewMatchup) => {
  if(newMatchup) {
    setSuccess(false)
    setTimeout(() => setNewMatchup(false), 1000);
  } else {
    setSuccess(false)
  }
}


const renderSuccessMessage = (failure, success, setSuccess, newMatchup, setNewMatchup) => {
  return (
    <div
      style={{
        opacity: success ? 1 : 0,
        display: failure ? "none" : "flex",
        zIndex: success ? "50" : "-10"
      }}
      className="battle-page__response-message success"
    >
      <p className="battle-page__response-message__text">
        {newMatchup ? "Reload page for new round!" : "Submitted successfully!"}
      </p>
      <img
        onClick={() => setBoth(newMatchup, setSuccess, setNewMatchup)}
        className="battle-page__response-message__exit"
        src={exit}
        alt="close icon"
      />
    </div>
  )
}

const renderFailMessage = (success, failure, setFailure) => {
  return (
    <div
      style={{
        opacity: failure ? 1 : 0,
        display: success ? "none" : "flex",
        zIndex: failure ? "50" : "-10"
      }}
      className="battle-page__response-message failure"
    >
      <p className="battle-page__response-message__text">
        Please reload the page and try again.
      </p>
      <img
        onClick={() => setFailure(false)}
        className="battle-page__response-message__exit"
        src={exit}
        alt="close icon"
      />
    </div>
  )
}


const formatVotes = (votes, userId, battleId) => {
  let formattedVotes = [];

  // remove empty objects
  let emptiedVotes = votes.filter(value => Object.keys(value).length !== 0);

  for(let i = 0; i < emptiedVotes.length; i++) {
    if(emptiedVotes[i]) {
      formattedVotes.push({userId: userId, songId: emptiedVotes[i].songId, matchupId: emptiedVotes[i].matchupId})
    }
  }
  return formattedVotes
}

const battleRenderLogic = (matchupData, matchupLoading, setSongId, userId, votes, setVotes, setUserList, battle) => {
  if(matchupLoading) {
    return (
      <BarLoader
        css={{
          margin: "0 auto",
          position: "relative",
          top: "40%"
        }}
         sizeUnit={"px"}
         size={50}
         color={colors.blackThought}
         loading={matchupLoading}
      />
    )
  } else if(matchupData && matchupData.matchups && matchupData.matchups.length > 0) {
    return matchupData.matchups.map((matchup, index) => renderMatchup(matchup, index, votes, setVotes, userId, setUserList, setSongId));
  } else {
    return (
      <div className="battle-page__no-matchups">
        <h4>Coming {battle.date}</h4>
      </div>
    )
  }
}

const renderVoteButton = (setGraphic, battle, matchupData, loadingBarProgress, submitVotes, voteMessage, votes, userId, battleId, setUserVotes, setLoadingBarProgress, setFailure, setVotes) => {
  if(userId) {
    return (
      <button
        style={{
          cursor: loadingBarProgress < 1 && userId ? "pointer" : "not-allowed",
          color: loadingBarProgress < 1 && userId ? colors.beachHouse : "#f1e3ce54",
          background: loadingBarProgress < 1 && userId ? colors.blackThought : "#2c201ebf"
        }}
        // disabled={votes && votes.length === 0}
        className="battle-page__vote__button"
        onClick={() => submitVotes(setGraphic, battle, matchupData, votes, userId, battleId, setUserVotes, setLoadingBarProgress, loadingBarProgress, setFailure, setVotes)}
      >
        {userId ? "cast your votes" : voteMessage}
      </button>
    )
  } else {
    return (
      <Link
        className="battle-page__vote__button__link"
        to="/signup"
        to={{pathname: '/signup', state: { prevPath: "/battle/" + battle.id }}}
      >
        <button
          className="battle-page__vote__button"
          >
          {userId ? "cast your votes" : voteMessage}
        </button>
      </Link>
    )
  }
}

const renderShare = (setGraphic) => {
  return (
    <div onClick={() => setGraphic(true)} className="battle-page__share-wrapper">
      <img src={shareIcon} className="battle-page__share-image"/>
      <p className="battle-page__share">share results</p>
    </div>
  )
}

const renderDownloadButton = (username, battle) => {
  return (
    <div className="battle-page__graphic__button-wrapper">
      <button
        onClick={() => printDocument(username, battle)}
        className="battle-page__graphic__button"
      >
        Download
      </button>
    </div>
  )
}

const printDocument = (username, battle) => {
  const input = document.getElementById('divToPrint');
  domtoimage.toBlob(input)
    .then(function (blob) {
         saveAs(blob, username + "_" + battle.title + "_" + 'results.png');
    })
    .catch(function (error) {
        console.error('oops, something went wrong!', error);
    });
}


const submitVotes = (setGraphic, battle, matchupData, userVotes, userId, battleId, setUserVotes, setLoadingBarProgress, loadingBarProgress, setFailure, setVotes) => {
  const votes = formatVotes(userVotes, userId, battleId)

  if(userVotes && userVotes.length < 1) {
    setFailure(true)
    setTimeout(() => setFailure(false), 4000);
  } else if(matchupData && matchupData.matchups && !loadingBarProgress) {
    setLoadingBarProgress(50)
    setUserVotes({
      variables: {votes: votes},
      update: (store, data) => updateCacheAfterSubmit(store, data, votes, battleId)
    })
    .then((result) => {
      setVotes([])
      setLoadingBarProgress(100)
      if((matchupData.matchups.length > 19 && battle.userVoteCount && battle.userVoteCount > 94) || (userVotes && userVotes.length > 18 && !userVotes.includes(undefined))) {
        setGraphic(true)
      }
    })
    .catch((error) => {
      setLoadingBarProgress(0)
      setFailure(true)
      setTimeout(() => setFailure(false), 4000);
    })
  }

}

const updateCacheAfterSubmit = (store, data, votes, battleId) => {
  const queryData = store.readQuery({ query: MATCHUP_QUERY, variables: {battleId: battleId} })
  const newData = data.data.multipleMatchupVotes.votes;
  const oldData = queryData.matchups;
  let oldMatchup = {}

  newData.map((vote) => {
     oldMatchup = oldData.filter(matchup => matchup.id === vote.matchup.id)
     oldData[oldMatchup] = vote.matchup
  })
  store.writeQuery({ query: MATCHUP_QUERY, variables: {battleId: battleId}, data: queryData})
}

const onLoaderFinished = (setLoadingBarProgress, setSuccess) => {
    setLoadingBarProgress(0)
    setSuccess(true)
    setTimeout(() => setSuccess(false), 3000);
}

const BattlePage = ({match, userId, username}) => {

  const [votes, setVotes] = useState([]);
  const [setUserVotes] = useMutation(MULTIPLE_VOTES)
  const battleId = match.params.battleId;
  const [userList, setUserList] = useState(false)
  const [songId, setSongId] = useState("")
  const [loadingBarProgress, setLoadingBarProgress] = useState(0)
  const [success, setSuccess] = useState(false)
  const [failure, setFailure] = useState(false)
  const [newMatchup, setNewMatchup] = useState(false)
  const [graphic, setGraphic] = useState(false)

  const size = {
    width: '100%',
    height: '100%',
  };
  const view = 'coverart'; // or 'coverart'
  const theme = 'black'; // or 'white'

  const { data, loading, error } = useQuery(BATTLE_QUERY, {
   variables: {
     battleId: battleId
    }
  });

  const { data: matchupData, loading: matchupLoading, error: matchupError } = useQuery(MATCHUP_QUERY, {
   variables: {
     battleId: battleId
    }
  });

  // const { data: dataOne } = useSubscription(
  //   NEW_MATCHUPS_SUBSCRIPTION,
  //   {variables: {
  //     battleId: battleId,
  //     userId: userId
  //   },
  //   onSubscriptionData: ({ client, subscriptionData }) => {
  //
  //   if(subscriptionData && subscriptionData.data.newMatchup) {
  //     // const queryData = client.readQuery({ query: BATTLE_QUERY, variables: {battleId: battleId} })
  //     //
  //     // const newData = subscriptionData.data.newMatchup
  //     // const oldData = queryData.battle
  //     // oldData.matchups.push(newData)
  //     // // eventually must check if matchup already exists
  //     //  client.writeQuery({
  //     //    query: BATTLE_QUERY,
  //     //    variables: { battleId: battleId},
  //     //    data: queryData,
  //     //  })
  //      setNewMatchup(true)
  //      setSuccess(true)
  //   }
  // }}
  // );


  if (loading) {
    return (
      <div className="battle-page">
        <BarLoader
          css={{
            margin: "0 auto",
            position: "relative",
            top: "40%"
          }}
           sizeUnit={"px"}
           size={50}
           color={colors.blackThought}
           loading={loading}
        />
      </div>
    )
  }
  if (error || matchupError) {
    if (error) return <ErrorPage />
   }


  if(data && data.battle) {

    const {battle} = data
    const voteMessage = isMobile ? "sign up to vote" : "sign up to vote and comment"
    const is_chrome = /chrome/i.test( navigator.userAgent );

    return (
      <div className={matchupLoading ? "battle-page battle-page-filter" : "battle-page"}>
        <LoadingBar
         progress={loadingBarProgress}
         height={loadingBarProgress === 0 ? 0 : 3}
         color={colors.carti}
         onLoaderFinished={() => onLoaderFinished(setLoadingBarProgress, setSuccess)}
        />
        {renderFailMessage(success, failure, setFailure)}
        {renderSuccessMessage(failure, success, setSuccess, newMatchup, setNewMatchup)}
        {!isMobile && <div className="battle-page__wrapper__one">
          <ChatList setLoadingBarProgress={setLoadingBarProgress} playlist={battle.playlistLink} userId={userId} setError={(choice) => setFailure(choice)} battle={battle}/>
          {battle.playlistLink && <div className="battle-page__playlist">
            <SpotifyPlayer
              uri={battle.playlistLink}
              size={size}
              view={view}
              theme={theme}
            />
          </div>}
        </div>}
        {battle.userVoteCount && battle.userVoteCount === 100 ? renderShare(setGraphic) : null}
        {graphic && <ResultsGraphic printDocument={() => printDocument(username, battle)} shareGraphic={graphic} closeGraphic={() => setGraphic(false)} username={username} battleId={battle.id} />}
        <div style={{width: isMobile && "100%"}} className="battle-page__wrapper__two">
          {userList && <UserList closeModal={() => setUserList(false)} songId={songId} />}
          <div className="battle-page__artist__header">
            {battle.artists.map((artist, index) => renderArtist(artist, index))}
          </div>
          <div className="battle-page__matchups">
          <div className="divider"></div>
            {battleRenderLogic(matchupData, matchupLoading, setSongId, userId, votes, setVotes, setUserList, battle)}
          </div>
          <div className="battle-page__vote__button-wrapper">
            {renderVoteButton(setGraphic, battle, matchupData, loadingBarProgress, submitVotes, voteMessage, votes, userId, battleId, setUserVotes, setLoadingBarProgress, setFailure, setVotes)}
          </div>
        </div>
      </div>
    )
  } else {
      return <ErrorPage noMatch={true} />
    }
  }


export default BattlePage



// const NEW_MATCHUPS_SUBSCRIPTION = gql`
//   subscription {
//     newMatchup {
//       id
//       currentUserVoted
//       songs {
//         id
//       }
//       round
//     }
//   }
// `

const BATTLE_QUERY = gql`
  query battle($battleId: ID!, $userId: ID) {
    battle(battleId: $battleId, userId: $userId) {
      id
      image
      artists {
        name
        image
        index
        userVoteCount
      }
      title
      image
      blurredImage
      playlistLink
      date
      userVoteCount
      chat {
        id
      }
    }
  }
`

const MATCHUP_QUERY = gql`
  query matchups($battleId: ID!) {
    matchups(battleId: $battleId) {
      id
      currentUserVoted
      songs {
        id
        name
        percentage
        currentUserVoted
        playlistLink
        index
      }
    }
  }
`

const MULTIPLE_VOTES = gql`
  mutation multipleMatchupVotes($votes: [CreateVoteInput!]!){
     multipleMatchupVotes(votes: $votes) {
      votes {
        id
        matchup {
          id
          currentUserVoted
          songs {
            id
            name
            percentage
            currentUserVoted
            votes {
              votes {
                id
              }
              count
            }
          }
        }
      }
    }
  }
`
