import React, { Component } from 'react'

import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import BarLoader from 'react-spinners/BarLoader'
import { colors } from '../styles/theme';
import check from '../images/check.svg'
import { formatDate } from './../utils'
import closeIcon from "../images/exit.svg"
import clearIcon from "../images/cancel.svg"
import { isMobile } from "react-device-detect";


class UserList extends Component {

  constructor() {
    super();

    this.state = {
      filter: "",
      orderBy: "latest",
      loading: false
    }
  }


  handleCloseClick = () => {
    this.props.closeModal()
  }

  handleChange = (e) => {
    e.preventDefault()
    this.setState({
      filter: e.target.value
    })
  }

  handleScroll = (e, fetchMore, data) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      this.setState({loading: true})
      fetchMore({
        variables: {
          first: 20,
          filter: this.state.filter,
          songId: this.props.songId,
          cursor: data.votesConnection.pageInfo.endCursor
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult.votesConnection.edges;
          const pageInfo = fetchMoreResult.votesConnection.pageInfo;

          this.setState({loading: false})
          return newEdges.length
          ? {
            votesConnection: {
              __typename: prevResult.votesConnection.__typename,
              edges: [...prevResult.votesConnection.edges, ...newEdges],
              pageInfo
            }
          }
          : prevResult
        }
      })
    }
  }


  render() {
    return (
      <div className="user-list">
        <img onClick={this.handleCloseClick} className="user-list__close" alt="close icon" src={closeIcon} />
        <div className="user-list__header">
          <h2 className="user-list__title">Voters</h2>
          <input
            onChange={e => this.handleChange(e)}
            value={this.state.filter}
            className="user-list__search"
            placeholder="search"
          />
          {!isMobile && <img
            style={{display: this.state.filter.length > 0 ? "block" : "none"}}
            onClick={() => this.setState({filter: ""})}
            className="user-list__clear" alt="clear icon" src={clearIcon}
            />}
        </div>
        <Query variables={{first: 20, filter: this.state.filter, songId: this.props.songId}} query={VOTE_CONNECTION_QUERY}>
          {({ loading, error, data, fetchMore }) => {
            if (loading) {
              return (
                <div className="user-list">
                  <BarLoader
                    css={{
                      margin: "0 auto",
                      position: "relative",
                      top: "40%"
                    }}
                     sizeUnit={"px"}
                     size={50}
                     color={colors.beachHouse}
                     loading={loading}
                  />
                </div>
              )
            }
            if (error) return <div className="list-error-msg">Something went wrong, reload the page to see the users.</div>

            const votes = data.votesConnection.edges;

            return (

                <div
                  onScroll={(e) => this.handleScroll(e, fetchMore, data)}
                  id="wrap"
                  className="user-list__wrapper"
                >

                {votes.map((vote, index) =>
                  <div key={index} className="user-list__user-wrapper">
                    <p className="user-list__username"  key={vote.node.id}>{vote.node.user.username}</p>
                    {vote.node.user.verified && <img className="user-list__check" alt="check icon" src={check} />}
                    <p className="user-list__date">{formatDate(vote.node.createdAt)}</p>
                  </div>)}

              </div>
              )
            }}
          </Query>
          <BarLoader
            css={{
              margin: "0 auto",
              position: "relative",
              top: "40px"
            }}
             sizeUnit={"px"}
             size={50}
             color={colors.beachHouse}
             loading={this.state.loading}
          />
      </div>
    )
  }
}

export default UserList


const VOTE_CONNECTION_QUERY = gql`
  query votesConnection($songId: ID!, $filter: String, $first: Int, $cursor: String) {
    votesConnection(songId: $songId, filter: $filter, first: $first, after: $cursor) {
      edges {
        cursor
        node {
          id
          user {
            id
            username
            name
            verified
          }
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`
