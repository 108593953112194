import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import shareIcon from '../images/share-icon.svg'
import ResultsGraphic from './ResultsGraphic'
import { isMobile } from "react-device-detect";
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';

class Battle extends Component {

  constructor() {
    super();

    this.state = {
      shareGraphic: false
    }
  }


  renderDate = (battle, main) => {
    if(battle.date && main) {
      return battle.date
    } else if(battle.date && !main) {
      if(battle.date.length === 10) {
        return battle.date.substring(0,5)
      } else {
        return battle.date.substring(0,4)
      }
    } else {
      return "TBA"
    }
  }

  renderShare = (main) => {
    return (
      <div onClick={() => this.setState({shareGraphic: true})} className={"battle-page__share-wrapper share" + (main ?  " main-share" : "")}>
        <img src={shareIcon} className="battle-page__share-image"/>
      </div>
    )
  }



  printDocument = (username, battle) => {
    const input = document.getElementById('divToPrint');
    domtoimage.toBlob(input)
      .then(function (blob) {
           saveAs(blob, username + "_" + battle.title + "_" + 'results.png');
      })
      .catch(function (error) {
          console.error('oops, something went wrong!', error);
      });
  }


  render() {
    const {battle, main, username} = this.props

    const is_chrome = /chrome/i.test( navigator.userAgent );


    return (
      <div className="wrapper">
        {this.state.shareGraphic && !main && <ResultsGraphic printDocument={() => this.printDocument(username, battle)} shareGraphic={this.state.shareGraphic} closeGraphic={() => this.setState({shareGraphic: false})} username={username} battleId={battle.id} />}
        <div className="image-wrapper">
          <img
            alt={battle.title}
            src={battle.image}
            className="image"
          />
        </div>
        <div className="contents">
          <p className="date">{this.renderDate(battle, main)}</p>
          <div className="title-wrapper">
            <p className="title">{battle.artists[0] && battle.artists[0].name}</p>
            <p className="title">v. {battle.artists[1] && battle.artists[1].name}</p>
          </div>
          <Link
            to={"battle/"+ battle.id}
            className="link"
          >
            Vote
          </Link>
        </div>
        {battle.userVoteCount && !main && battle.userVoteCount === 100 ? this.renderShare(main) : null}
      </div>
    )
  }
}

export default Battle
