import React, {useState} from 'react'
import BattleList from './BattleList'
import Footer from './Footer'
import Login from './Login'
import BattlePage from '../pages/BattlePage'
import SplashPage from '../pages/SplashPage'
import AboutPage from '../pages/AboutPage'
import UserList from './UserList'
import Header from './Header'
import ErrorBoundary from './ErrorBoundary'
import ErrorPage from './ErrorPage'
import { withRouter } from 'react-router'

import { Switch, Route } from 'react-router-dom'
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';
// import { AUTH_TOKEN } from '../constants'
import '../styles/App.scss'

const pathname = window.location.pathname;

const NoMatch = ({ location }) => (
  <ErrorPage noMatch={true} />
)

const App = ({client}) => {

  const {
      data,
      // loading,
      // error
  } = useQuery(CURRENT_USER_QUERY);

  const [filter, setFilter] = useState("");

  return (
    <div className="background">
      <ErrorBoundary>
        <div className="app-content-wrapper">
          {pathname !== "/find-out-more" && <Header client={client && client} home={pathname === "/"} search={pathname !== "/signup"} triggerRoute={pathname !== "/"} filter={filter} handleSearch={(inputText) => setFilter(inputText)} pathname={pathname} />}
          <Switch>
            <Route exact path="/battle/:battleId" render={(props) => <BattlePage filter={filter} username={data && data.currentUser && data.currentUser.username} userId={data && data.currentUser && data.currentUser.id} {...props} />} />
            <Route exact path="/" render={(props) => <BattleList username={data && data.currentUser && data.currentUser.username} filter={filter} {...props} />} />
            <Route exact path="/login" render={(props) => <Login login={true} {...props} />} />
            <Route exact path="/signup" component={Login} />
            <Route exact path="/song/:songId/users/" component={UserList} />
            <Route exact path="/find-out-more" component={SplashPage} />
            <Route exact path="/about" component={AboutPage} />
            <Route component={NoMatch} />
          </Switch>
        </div>
          {pathname !== "/find-out-more" && <Footer />}
      </ErrorBoundary>
    </div>
  )
}

const CURRENT_USER_QUERY = gql`
  query currentUser {
    currentUser {
      id
      username
    }
  }
`

export default withRouter(App)
